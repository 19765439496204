

<template>
  <div class="main-conent main-conent-minheight" v-loading="loading">
    <el-card shadow="never" class="border-none">
      <div slot="header">
        <el-row>
          <el-col :span="6">
            <el-button type="primary" @click="dataBinding()">刷新</el-button>
          </el-col>
          <el-col :span="18">
            <el-checkbox-group v-model="checkList">
              <el-checkbox label="待审核" border @change="handleCheckboxChanged"></el-checkbox>
              <el-checkbox label="已审核" border @change="handleCheckboxChanged"></el-checkbox>
              <el-checkbox label="已删除" border @change="handleCheckboxChanged"></el-checkbox>
            </el-checkbox-group>
          </el-col>

        </el-row>
      </div>

      <el-table :data="tableData" stripe fit>
        <el-table-column prop="projectName" label="课程名称" width="80">
        </el-table-column>
        <el-table-column prop="finishDate" label="交付时间" width="70">
        </el-table-column>
        <el-table-column prop="finishArea" label="交付地点"  width="70">
        </el-table-column>
        <el-table-column prop="peopleCount" label="参与人数" width="70">
        </el-table-column>
        <el-table-column prop="summary" label="项目概述">
        </el-table-column>
        <el-table-column prop="bright" label="项目亮点">
        </el-table-column>
        <el-table-column prop="remark" label="后台备注">
        </el-table-column>
        <el-table-column prop="state" label="是否上架" width="80">
          <template slot-scope="scope">
            <el-tag :type="scope.row.state===0 ? 'primary' : 'info'" disable-transitions>
              {{scope.row.state===0 ?'待审核':'已审核'}}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="updateTimeStr" label="更新时间" width="90">
        </el-table-column>
        <el-table-column label="操作"  width="120">
          <template slot-scope="scope">
            <el-button type="text" @click="handleInfoModel(scope.row)">查看详细</el-button>
            <el-button v-if="scope.row.state==0" type="text" @click="handlePassModel(scope.row)">通过</el-button>
            <el-button type="text" @click="handleRemarkModel(scope.row)">修改备注</el-button>
            <el-button v-if="scope.row.state!=99" @click="handleDeleteModel(scope.row)" type="text" style="color:red;">
              删除</el-button>
          </template>
        </el-table-column>
      </el-table>

      <div class="margin-t16 text-right">

      </div>

    </el-card>
  </div>
</template>
<script>
import { getList, updateState, updateRemark } from '@/service/community.js';

export default {
  data() {
    return {
      checkList: ['待审核'],
      loading: false,
      searchOpen: false,
      totalCount: 0,
      searchForm: {
        page: 1,
        pageSize: 20,
        states: '0'
      },
      tableData: []
    };
  },
  created() {
    this.dataBinding();
  },
  methods: {
    //跳转编辑案例页
    handleEditModel(row) {
      this.$router.push({ name: 'article-edit', query: { articleId: row.id } });
    },
    //复选框变更
    handleCheckboxChanged(e) {

      this.dataBinding();
    },
    //加载页面数据
    dataBinding() {
      this.loading = true;
      this.searchForm.states = '';
      let stateArray = [];
      this.checkList.forEach(item => {
        if (item === '待审核')
          stateArray.push(0);
        else if (item === '已审核')
          stateArray.push(1);
        else if (item === '已删除')
          stateArray.push(99);
      });
      this.searchForm.states = stateArray.join(',');
      console.log(this.searchForm);

      getList(this.searchForm).then(res => {
        console.log(res);
        this.totalCount = res.total;
        this.tableData = res.list;
        this.loading = false;
      });
    },
    //跳转新增案例页
    handleEdit() {
      this.$router.push({ name: 'article-edit' });
    },
    //提交备注
    handleRemarkModel(row) {
      this.$prompt('请输入备注信息', '编辑备注', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(({ value }) => {
        this.loading = true;
        let req = { id: row.id, remark: value };
        updateRemark(req).then(res => {
          if (res === true) {
            this.dataBinding();
          }
          else {
            this.$message("操作失败");
          }
          this.loading = false;
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消输入'
        });
      });
    },
    //跳转详细页
    handleInfoModel(row){
        this.$router.push({ name: 'community-info', query: { communityId: row.id } });
    },
    //通过审核
    handlePassModel(row) {
      this.loading = true;
      let req = { id: row.id, state: 1 };
      updateState(req).then(res => {
        if (res === true) {
          this.dataBinding();
        }
        else {
          this.$message("操作失败");
        }
        this.loading = false;
      });
    },
    //删除案例
    handleDeleteModel(row) {
      this.loading = true;
      updateState({ id: row.id, state: 99 }).then(res => {
        if (res === true) {
          this.dataBinding();
        }
        else {
          this.$message("删除操作失败");
        }
        this.loading = false;
      });
    }
  }
};
</script>